const MENU_TRANSACOES_MOCK = [
  {
    id: 1,
    descricao: "Home",
    path: "/home",
  },
  {
    id: 2,
    descricao: "Meus Dados",
    path: "/meus-dados",
  },
  {
    id: 3,
    descricao: "Histórico de Economia",
    path: "/transacao",
  },
  {
    id: 4,
    descricao: "Extrato de Pontos",
    path: "/extrato-de-pontos",
  },
  {
    id: 5,
    descricao: "Meus Amigos",
    path: "/meus-amigos",
  },
  {
    id: 6,
    descricao: "Alterar senha",
    path: "/alterar-senha",
  },
];

export default MENU_TRANSACOES_MOCK;