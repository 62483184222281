import Router from "router";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { SidebarDrawerProvider } from "contexts/SidebarDrawerContext";
import AppProvider from "hooks/index";
import { queryClient } from "services/queryClient";
import api from "services/api";
import { theme } from "styles/theme";
import Cart from "contexts/cart";
import Store from "contexts/store";
import Activated from "contexts/activated";

api.interceptors.request.use((config: any) => {
  config.headers.Authorization = localStorage.getItem("@DClube:Admintoken") ?? localStorage.getItem("@DClube:token") ?? "";
  return config;
});

function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <SidebarDrawerProvider>
          <Store>
            <Activated>
              <Cart>
                <AppProvider>
                  <Router />
                </AppProvider>
              </Cart>
            </Activated>
          </Store>
        </SidebarDrawerProvider>
      </ChakraProvider>
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  );
}

export default App;
