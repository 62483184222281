import React, { ReactNode, useRef, useState } from "react";
import {
  Button,
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormLabel,
  Input,
  ModalFooter,
  useMediaQuery,
  Flex,
} from "@chakra-ui/react";

import api from "services/api";
import { useCustomToast } from "hooks/useToast";

interface ModalProps {
  title: string;
  titleInput?: string;
  isInput?: boolean;
  placeholderInputText?: string;
  buttonSubmitText: string;
  isCancelButton?: boolean;
  buttonCancelText?: string;
  children?: ReactNode;
  isOpen: boolean;
  isRegulation?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onSubmitModal?: () => void;
}

export const Modal = ({
  title,
  children,
  isInput = false,
  titleInput,
  placeholderInputText,
  buttonSubmitText,
  isCancelButton = false,
  buttonCancelText,
  isOpen,
  isRegulation,
  onOpen,
  onClose,
  onSubmitModal,
}: ModalProps) => {
  const [email, setEmail] = useState("");
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [isMobileSize] = useMediaQuery("(max-width: 992px)");
  const { getToast } = useCustomToast();

  const handleSubmit = async () => {
    try {
      const { data } = await api.get("cadastro/esqueciSenha", {
        params: { login: email },
      });
      getToast({ messageError: data?.mensagem, status: "success" });
    } catch (error) {
      console.log(error);
      getToast({
        messageError: "E-mail não encontrado em nossa base da dados!",
        status: "info",
      });
    }
    onClose && onClose();
  };

  return (
    <ChakraModal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose && (onClose as any)}
    >
      <ModalOverlay backdropFilter='blur(10px)'/>
      <ModalContent maxWidth={isMobileSize ? "90%" : undefined}>
        <ModalHeader  padding={7}>
          {title}
        </ModalHeader>
        {!isRegulation && <ModalCloseButton />}
        <ModalBody pb={6}>
          {isInput && (
            <>
              <FormLabel >
                {titleInput}
              </FormLabel>
              <Input
                
                ref={initialRef}
                placeholder={placeholderInputText}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
            </>
          )}

          {children}
        </ModalBody>

        <Flex alignItems={'flex-end'} justifyContent={'flex-end'} padding={5}>
          <Button
            
            colorScheme="red"
            mr={3}
            onClick={isRegulation ? onSubmitModal : handleSubmit}
          >
            {buttonSubmitText}
          </Button>
          {isCancelButton && (
            <Button
              
              colorScheme="red"
              mr={3}
              onClick={onClose}
            >
              {buttonCancelText}
            </Button>
          )}
        </Flex>
      </ModalContent>
    </ChakraModal>
  );
};
