import React, { createContext, ReactNode, useReducer } from 'react';

const Reducer = (state, action) => {
  const resetStates = {
    isShow: false,
  };

  switch (action.type) {
    case 'CHANGE_SHOW_CART':
      return {
        ...state,
        ...resetStates,
        isShow: action.isShow,
      };
    default:
      return state;
  }
};

const initialState = {
  isShow: false,
};

const Cart = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};

export const Context = createContext(initialState);
export default Cart;
