import { Dispatch, SetStateAction, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";

import { StateProps } from "interfaces/categories/ICategory";

interface BreadcrumbProps {
  uri?: string;
  uriPai?: string;
  uriFilho?: string;
  categoriaSelecionadaNome: string;
  categoriaSelecionadaPai: string;
  categoriaSelecionadaId?: number;
  subCategories?: any;
  subCategoriesPai?: any;
  categorias?: any;
}

export const Breadcrumb_v2 = ({
  uri,
  uriPai,
  uriFilho,
  categoriaSelecionadaId,
  subCategories,
  subCategoriesPai,
  categoriaSelecionadaNome,
  categorias,
  categoriaSelecionadaPai
}: BreadcrumbProps) => {
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const regex = /[\u0300-\u036f]|[^0-9a-zA-Z]/g;
  const [isMobileSize] = useMediaQuery("(max-width: 992px)");

  return (
    <ChakraBreadcrumb
      fontSize="15px"
      marginLeft={isMobileSize ? 1.5 : 0}
      separator={
        <Text margin={0} color="#EC1C24">
          {">"}
        </Text>
      }
      marginBottom={5}>

      <BreadcrumbItem>
        <BreadcrumbLink
          fontSize="15px"
          href="/home"
          fontWeight='normal'
          _hover={{
            color: "#EC1C24"
          }}>
          Home
        </BreadcrumbLink>
      </BreadcrumbItem>

      {uriPai && categoriaSelecionadaPai &&
        <BreadcrumbItem>
          <BreadcrumbLink
            fontSize="15px"
            fontWeight={uriPai && !uriFilho && !uri ? 'bold' : 'normal'}
            color={uriPai && !uriFilho && !uri ? '#EC1C24' : ''}
            onClick={() => {
              var paiId = subCategoriesPai[0]?.categoriaPaiId ?? categoriaSelecionadaId
              navigate(`/categoria/${uriPai}`, {
              state: {
                uriPai,
                // subCategories,
                subCategoriesPai,
                categoriaSelecionadaPai,
                categoriaSelecionadaId : paiId
                // categorias
              },
            })}}
            _hover={{
              color: "#EC1C24",
              textDecoration: "underline"
            }}>
            {categoriaSelecionadaPai}
          </BreadcrumbLink>
        </BreadcrumbItem>
      } 

      {uri && categoriaSelecionadaNome &&
        <BreadcrumbItem>
          <BreadcrumbLink
            fontSize="15px"
            fontWeight={uri && !uriFilho ? 'bold' : 'normal'}
            color={uri && !uriFilho ? '#EC1C24' : ''}
            onClick={() => {
              var paiId = subCategories[0]?.categoriaPaiId ?? categoriaSelecionadaId

              navigate(`/categoria/${uriPai}/${uri}`, {
              state: {
                uriPai,
                uri,
                subCategories,
                categoriaSelecionadaNome,
                categoriaSelecionadaPai,
                subCategoriesPai,
                categoriaSelecionadaId : paiId
                // categorias
              }
            })}}
            _hover={{
              color: "#EC1C24"
            }}>
            {categoriaSelecionadaNome}
          </BreadcrumbLink>
        </BreadcrumbItem>
      }

      {uriFilho &&
        <BreadcrumbItem>
          <BreadcrumbLink
            fontSize="15px"
            fontWeight={uriFilho ? 'bold' : 'normal'}
            color={uriFilho ? '#EC1C24' : ''}
            onClick={() => {
              //debugger;
              navigate(`/categoria/${uriPai}/${uri}/${uriFilho}`, {
              state: {
                uriPai,
                uri,
                uriFilho,
                categoriaSelecionadaId,
                categoriaSelecionadaNome,
                categoriaSelecionadaPai,
                subCategories,
                // categorias,
                subCategoriesPai
              }
            })}}
            _hover={{
              color: "#EC1C24"
            }}>
            {subCategories?.filter((x : any) => x.uri == uriFilho)[0]?.descricao}
          </BreadcrumbLink>
        </BreadcrumbItem>
      }
    </ChakraBreadcrumb>
  );
};
