export function characterDelimiter(text: string, totalCharacters = 40) {
  let shortText = text;

  if (text == undefined || text == "undefined" || text == "") {
    return text;
  }

  if (text.length > totalCharacters) {
    let total = totalCharacters;
    shortText = text.substring(0, total).concat("...");
  }

  return shortText;
}
