import { useContext, useEffect, useState } from "react";
import {
  Button,
  Flex,
  Heading,
  Image,
  useMediaQuery,
  Box,
  VStack,
  Text,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input
} from "@chakra-ui/react";

import boxEmpty from 'assets/icons/box-empty.png';

import api from "services/api";
import { characterDelimiter } from "utils/characterDelimiter";
import { useCustomToast } from "hooks/useToast";
import { Context as ContextActivated } from 'contexts/activated';
import { useAuth } from "hooks/useAuth";
import { formattingNumberWithDecimals } from "utils";
import "./style.scss";
import TimerCount from "components/Header/components/timer";
import { useNavigate } from "react-router-dom";
import { Breadcrumb_v2 } from "components/Breadcrumb/index_v2";

const width = window.innerWidth

const DiscountActivatedPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isMobileSize] = useMediaQuery("(max-width: 992px)");
  const { getToast } = useCustomToast();
  const size = useWindowSize();
  const [stateActivated, dispatchActivated] = useContext(ContextActivated)
  const [productsActiveded, setProductsActiveded] = useState([]);

  useEffect(() => {
    verifyIsActivaded()
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  
  const verifyIsActivaded = async () => {
    setLoading(true)
    let { data } = await api.get("/motorDePontos/consultarCarrinhoAtivo");
    if(data.length > 0){
      setProductsActiveded(data)
      dispatchActivated({ type: "UPDATE_QUANT_ACTIVATED", quantidade: data.length})
    }
    setLoading(false)
  }
  
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: 0,
      height: 0,
    });
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
  }

  const formateDate = (date) => {
    date = new Date(date);
    date.setHours(date.getHours() -  3);
    const originalDate = new Date(date);
    const day = originalDate.getDate().toString().padStart(2, '0');
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
    const year = originalDate.getFullYear();
    const hours = originalDate.getHours().toString().padStart(2, '0');
    const minutes = originalDate.getMinutes().toString().padStart(2, '0');
    const seconds = originalDate.getSeconds().toString().padStart(2, '0');
    const formattedDate = `${day}/${month}/${year} às ${hours}:${minutes}:${seconds}`;
    return formattedDate
  }

  return (
    <>
      {!isMobileSize &&
        <Flex
            width="100%"
            maxWidth="1420px"
            margin="0px auto"
            justifyContent="space-between"
            alignItems="baseline"
            flexDirection={ isMobileSize ? 'column' : 'row' }
            marginTop={8}
          >
            <div style={{paddingLeft: isMobileSize ? 10 : 0}}>
              <Breadcrumb_v2
                uri={'carrinho'}
                categoriaSelecionadaNome={'Produtos ativos'}
                categoriaSelecionadaPai={''}
              />
            </div>
        </Flex>
      }
      <Flex
        width="100%"
        maxWidth="1200px"
        paddingBottom={100}
        margin={isMobileSize ? "10px auto 10px auto" : "10px auto 10px auto"}
      >
        <div className="container-cart">
          <div className="container-cart-items">
            {/**
            <div className="title-cart">
              <span>
                Descontos ativos
              </span>
              <br/>
              <span className="subtitle">
                Faça o pagamento dentro do prazo para aproveitar o desconto!
              </span>
            </div>
             */}
            {isMobileSize &&
              <span className="title-screen">
                Seus produtos com descontos ativos
              </span>
            }
            <Flex width="100%" maxWidth="1220" margin="0px auto">
              <Flex
                wrap="wrap"
                gap="20px"
                maxWidth="1420px"
                width="100%"
                margin="0px auto"
                marginTop={12}
                marginLeft={isMobileSize ? 0 : '5%'}
              >

                {productsActiveded.length == 0 && !loading &&
                  <div style={{width: '100%', padding: 10,  flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <span style={{fontSize: 30, color:  '#344054', textAlign: 'center', fontWeight: '500'}}>
                        Nenhum desconto <br/>ativo até o momento
                    </span>
                    <img src={boxEmpty} style={{height: 100, width: 100, marginTop: 30}} />
                    <button onClick={() => {
                      navigate('/resgate')
                    }} style={{height: 50, cursor: 'pointer', marginTop: 40, paddingLeft: 25, paddingRight: 25, backgroundColor: '#EC1C24', borderRadius: 999, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <span style={{color: '#fff', fontSize: 20, fontWeight: '600'}}>
                            Ativar descontos
                        </span>
                    </button>
                  </div>
                }

                {loading ? (
                  <Flex margin="100px auto" justifyContent="center">
                    <Spinner color="#EC1C24" size={"xl"} />
                  </Flex>
                ) : (
                  productsActiveded.map((item, index) => {
                    return (
                      <VStack key={index} style={{marginLeft: isMobileSize ? 0: 20, marginRight: isMobileSize ? 0: 20}}>
                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          height={"260px"}
                          width={isMobileSize ? size.width /2.2 : "220px"}
                          borderRadius="10px"
                          overflow="hidden"
                          cursor={'pointer'}
                          position={'relative'}
                        >
                          {/**
                          <div style={{position: 'absolute', top: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#EC1C24', paddingLeft: 10, paddingRight: 10, borderRadius: 999}}>
                            <TimerCount date={item.dataValidade} size={16} color={"#fff"} onTimerFinish={() => verifyIsActivaded()} />
                          </div>
                           */}
                          <Image
                            fallbackSrc="https://via.placeholder.com/230?text=Carregando..."
                            src={item.imagem}
                            objectFit="contain"
                            marginBottom={isMobileSize ? 10 : 0}
                          />
                        </Flex>

                        <Box
                          width={isMobileSize ? size.width/2.2 : "220px"}
                          height="60px"
                          borderRadius="12px"
                          zIndex={1}
                        >
                          <Text
                            fontSize="16px"
                            fontWeight="bold"
                            color="#10125A"
                            textAlign="center"
                            marginTop={isMobileSize ? -10 : 0}
                          >
                            {item.nome ? 
                              characterDelimiter(item.nome, 40)
                              :
                              <span>
                                {item.ean}
                              </span>
                            }
                            
                          </Text>
                        </Box>
                        <div className="container-discount-points">
                          <div className="item-discount">
                            <div className="title">
                              <span>
                                Descontos
                              </span>
                            </div>
                            <div className="sub-title">
                              <span>
                                {formattingNumberWithDecimals(item.valorDescontoTotal).replace('.', ',')} Total
                              </span>
                              <span style={{fontSize: 10}}>
                                {formattingNumberWithDecimals(item.valorDescontoUnitario).replace('.', ',')}/un.
                              </span>
                            </div>
                          </div>
                          <div style={{height: '100%', flexDirection: 'column', width: 1, display: 'flex', background: '#EC1C24', justifyContent: 'center', alignItems: 'center'}}>
                          <div style={{height: '10%', width: 1, backgroundColor: '#EC1C24'}} />
                            <div style={{height: '80%', width: 1, backgroundColor: '#bdbdbd'}} />
                            <div style={{height: '10%', width: 1, backgroundColor: '#fff', border: 'none'}} />
                          </div>
                          <div className="item-points">
                            <div className="title">
                              <span>
                                Pontos usados
                              </span>
                            </div>
                            <div className="sub-title">
                              <span>
                                {item.pontosTotal}
                              </span>
                            </div>
                          </div>
                        </div>
                        <span className="text-validate">
                          Válido até {formateDate(item.dataValidade)}
                        </span>

                        {/** 
                        <Box
                          width={isMobileSize ? size.width/2.2 : "220px"}
                          borderRadius="12px"
                          zIndex={1}
                        >
                          <Text
                            fontSize="16px"
                            fontWeight="bold"
                            color="#10125A"
                            textAlign="center"
                            marginTop={isMobileSize ? -10 : 0}
                          >
                            {characterDelimiter(item.ean, 40)}
                          </Text>
                          <Text
                            fontSize="16px"
                            fontWeight="bold"
                            color="#10125A"
                            textAlign="center"
                            marginTop={-4}
                          >
                            {item.quantidade} unidades
                          </Text>
                        </Box>
                        <Flex  width={"100%"} flexDirection="column" alignItems={"center"} justifyContent={"center"}>
                          <span style={{color: '#EC1C24', fontSize: 14, fontWeight: 'bold'}}>
                            Desconto total
                          </span>
                          <span style={{color: '#EC1C24', fontSize: 14, fontWeight: 'bold'}}>
                            {formattingNumberWithDecimals(item.valorDescontoTotal).replace('.', ',')}
                          </span>
                        </Flex>
                        <Flex _focus={{border: 'none', backgroundColor: "transparent", outline:0}} width={isMobileSize ? size.width/2.2 : "220px"} flexDirection="column">
                          <Flex width={'100%'} alignItems="center" justifyContent={"center"} border="2px solid #EC1C24" borderTopLeftRadius={10} borderTopRightRadius={10} padding={1}>
                            <Flex direction={"column"} alignItems="center" justifyContent={"center"}>
                              <span style={{color: '#10125A', fontSize: 16, fontWeight: 'bold'}}>
                                Pontos
                              </span>
                              <span style={{color: '#10125A', fontSize: 20, padding: 2, fontWeight: 'bold'}}>
                                {item.pontos}
                              </span>
                            </Flex>
                          </Flex>
                          <div style={{width: '100%', cursor: 'pointer',  backgroundColor: item.selected ? "#10125A" : '#EC1C24',  borderBottomRightRadius: 8, borderBottomLeftRadius: 8, alignItems: 'center', justifyContent: 'center'}} >
                            <Flex flex={1} height="30px" justifyContent="center" alignItems="center" >
                              <span style={{fontSize: isMobileSize ? 15 : 18, color: '#fff', fontWeight: 'bold'}}>
                                {item.pontosTotal}
                              </span>
                            </Flex>
                          </div>
                        </Flex>
                        */}
                      </VStack>
                    )
                }))}
              </Flex>
          </Flex>
          <br/>
          <br/>
          <br/>
          </div>
        </div>
      </Flex>
    </>
  );
};

export default DiscountActivatedPage;
