import { Navigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";

interface PublicRouteProps {
  children: any;
}

function PublicRoute({ children }: PublicRouteProps) {
  const { user } = useAuth();
  return !user ? children : <Navigate to="/home" />;
}

export default PublicRoute;
