import { Flex, Heading, Text, useMediaQuery, Box } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

interface HeadingTableProps {
  isShowTable: boolean;
  setIsShowTable: Dispatch<SetStateAction<boolean>>;
}

export const HeadingTable = ({
  isShowTable,
  setIsShowTable,
}: HeadingTableProps) => {
  const [isMobileSize] = useMediaQuery("(max-width: 992px)");

  function handleToggleShowTable() {
    setIsShowTable(!isShowTable);
  }

  return (
    <Flex flexDirection="column" margin={isMobileSize ? "0px auto" : "none"}>
      {isMobileSize ? (
        <Flex justifyContent="space-between" alignItems="center">
          <Heading
            
            fontSize="18px"
            fontWeight="bold"
            color="#10125A"
            textDecoration={isShowTable ? "underline" : "none"}
            textDecorationColor={isShowTable ? "#EC1C24" : "none"}
          >
            Extrato de Pontos
          </Heading>
          <Box onClick={handleToggleShowTable}>
            {isShowTable ? (
              <FiChevronUp size={40} color="#EC1C24" />
            ) : (
              <FiChevronDown size={40} color="#EC1C24" />
            )}
          </Box>
        </Flex>
      ) : (
        <Heading
          
          fontSize="20px"
          fontWeight="bold"
          color="#10125A"
        >
          Extrato de Pontos
        </Heading>
      )}

      <Text
        margin={isMobileSize ? "10px 0px 20px 0px" : "10px 0px 20px 0px"}
        
        fontSize="15px"
        fontWeight="normal"
        color="#6D6C6C"
      >
        Confira abaixo todas as ativações feitas utilizando seus pontos no cadastro DClube
      </Text>
    </Flex>
  );
};
