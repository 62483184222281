import { useState, useRef } from "react";
import { Button, Flex, Heading, Spinner, Textarea, useMediaQuery, VStack } from "@chakra-ui/react";
import { SecondHeader } from "components/SecondHeader";
//import { HeadingMyData } from "../../components/HeadingMyData";
import "./style.scss";
import { Input } from "components/Input";
import { BottomSpace } from "components/BottomSpace";
import { useCustomToast } from "hooks/useToast";
import api from "services/api";
import { GoogleReCAPTCHA } from "components/GoogleReCAPTCHA";
import { GoBack } from "components/goBack";

export default function Contato() {
    const [isMobileSize] = useMediaQuery("(max-width: 992px)");
    const [loading, setLoading] = useState(false)
    const [mensagem, setMensagem] = useState('')
    const [name, setName] = useState('')
    const [sobrenome, setSobrenome] = useState('')
    const [email, setEmail] = useState('')
    const { getToast } = useCustomToast();
    const [token, setToken] = useState("");
    const captchaRef = useRef<any>(null);
    const siteKey = "6LdR_C8iAAAAADmc32FdMbysqGmAzldns-GNA2zc";

    const saveResume = async () => {
        
        if(!name){
            return  getToast({ messageError: 'Preencha seu nome!', status: "error" });
        }
        if(name.length < 3){
            return  getToast({ messageError: 'Preencha um nome válido!', status: "error" });
        }

        if(!sobrenome){
            return  getToast({ messageError: 'Preencha seu sobrenome!', status: "error" });
        }
        if(sobrenome.length < 3){
            return  getToast({ messageError: 'Preencha um sobrenome válido!', status: "error" });
        }

        var validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if(!email){
            return  getToast({ messageError: 'Preencha seu e-mail!', status: "error" });
        }
        
        if (!email.match(validEmailRegex)) {
            return  getToast({ messageError: 'Preencha um e-mail válido!', status: "error" });
        }

        if(!mensagem){
            return  getToast({ messageError: 'Preencha sua mensagem!', status: "error" });
        }
        if(mensagem.length < 3){
            return  getToast({ messageError: 'Preencha uma mensagem válida!', status: "error" });
        }

        let url:(boolean | string) = window.location.href
        url = url.indexOf('localhost') !== -1
        if (!url && !token) {
            getToast({
              messageError: "Por favor, confirme que você não é um robô",
              status: "warning",
            });
            return;
        }
        
        const params = {
            nome: name + ' ' + sobrenome,
            email: email,
            mensagem: mensagem
        };

        setLoading(true)
        try {
            await api.post("/contato", params)
            getToast({
              messageError: "Já recebemos seu contato. Obrigado!",
              status: "success",
            });
            clearInputs()
        } catch (error) {
            getToast({ messageError: 'Houve um erro ao enviar sua solicitação.', status: "error" });
            console.log(error);
        } finally {
            setLoading(false)
        } 

    }

    const clearInputs = () => {
        setName('')
        setSobrenome('')
        setEmail('')
        setMensagem('')
    }

    return (
        <>
            <SecondHeader />
            <Flex
                width="100%"
                maxWidth="1420px"
                justifyContent="space-between"
                alignItems="baseline"
                marginLeft={'auto'}
            >
                {!isMobileSize ? 
                    <div style={{marginLeft: '7.5%', marginTop: 20}}> 
                        <GoBack />
                    </div>
                    : <></>
                }
                <Flex flexDirection="column" margin="20px 0px" />
            </Flex>
            <Flex width="100%" maxWidth="1420px" margin="0px auto" justifyContent="center" marginBottom={20}>
                
                <Flex width="100%" maxWidth="900px" flexDirection={'column'} >
                    <Heading
                        fontSize="40px"
                        fontWeight="bold"
                        color="#10125A"
                        textAlign={isMobileSize ? 'center' : 'left'}
                    >
                        Contato
                    </Heading>

                    <span style={{ marginTop: 50, fontWeight: 500, color: '#10125A', lineHeight: 1.2, textAlign: isMobileSize ? 'center' : 'left' }}>
                        Fale conosco através do formulário abaixo.
                    </span>
                    <span style={{fontSize: 12, fontWeight: 500, color: '#10125A', lineHeight: 1.2, textAlign: isMobileSize ? 'center' : 'left' }}>
                        *campos obrigatórios
                    </span>
                    <div style={{ padding: isMobileSize ? 15 : 0, }}>

                        <div style={{ display: 'flex',flexDirection: isMobileSize ? 'column' : 'row', marginTop: 60, width: '100%', alignItems: isMobileSize ? 'center' : 'flex-start' }}>
                            <Input
                                nameInput="nome"
                                fontSize="16px"
                                fontWeight="bold"
                                label="Nome*"
                                placeholder="ex: Antonio"
                                type="name"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                            />
                            <div style={{ margin: 20 }} />
                            <Input
                                nameInput="sobrenome"
                                pattern="^[a-zA-Z]$"
                                fontSize="16px"
                                fontWeight="bold"
                                label="Sobrenome*"
                                placeholder="ex: Perez"
                                type="sobrenome"
                                onChange={(e) => setSobrenome(e.target.value)}
                                value={sobrenome}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, width: '100%', alignItems: isMobileSize ? 'center' : 'flex-start' }}>
                            <div style={{ flex: 1 }}>
                                <Input
                                    nameInput="email"
                                    fontFamily="Montserrat, sans-serif"
                                    fontSize="14px"
                                    fontWeight="bold"
                                    label="E-mail*"
                                    placeholder="Ex: antonio.perez@gmail.com"
                                    type="e-mail"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30, width: '100%', }}>
                            <span style={{ fontWeight: 'bold', paddingBottom: 10, fontSize: 13 }}>
                                Mensagem* 
                            </span>
                            <Textarea
                                value={mensagem}
                                onChange={(e) => setMensagem(e.target.value)}
                                placeholder='Digite sua mensagem aqui.'
                                size='sm'
                                focusBorderColor="#EC1C24"
                                height={250}
                                maxLength={500}
                            />
                            <span style={{ fontWeight: '400', paddingBottom: 10, fontSize: 13, paddingTop: 5 }}>
                                Máximo de 500 caracteres
                            </span>
                        </div>
                        
                        <Flex marginTop={20} alignItems={'center'} flexDirection={isMobileSize ? 'column' : 'row'} >
                            <Flex flex={1}>
                                <GoogleReCAPTCHA
                                    handleChangeReCAPTCHA={(token) => setToken(token ?? "")}
                                    handleExpiredReCaptcha={() => setToken("")}
                                    captchaRef={captchaRef}
                                    sitekey={siteKey ?? ""}
                                    lang="pt-BR"
                                />
                            </Flex>
                            <Flex flex={1} alignItems={'flex-end'} justifyContent={'flex-end'}>
                                <Button
                                    fontSize="20px"
                                    minWidth={230}
                                    fontWeight="regular"
                                    color="#FFFF"
                                    backgroundColor="#ec1c24"
                                    border="solid 1px #FFFF"
                                    borderRadius={5}
                                    marginTop={isMobileSize ? 10 : 0}
                                    _active={{ backgroundColor: "#ec1c24" }}
                                    _focus={{ backgroundColor: "#ec1c24" }}
                                    _hover={{ backgroundColor: "#E53E3E" }}
                                    onClick={() => saveResume()}
                                    disabled={loading}
                                    >
                                    {loading ?
                                        <Spinner color="white" />
                                        : 
                                        'Enviar cadastro'
                                    }
                                </Button>
                            </Flex>
                        </Flex>
                    </div>
                </Flex>
            </Flex>
            <BottomSpace />
        </>
    );
};
