import React, { createContext, useReducer } from 'react';

const Reducer = (state, action) => {
  const resetStates = {
    quantidade: 0,
  };
  switch (action.type) {
    case ('UPDATE_QUANT_ACTIVATED'):
      return {
        ...state,
        ...resetStates,
        quantidade: action.quantidade,
      };
    default:
      console.log('action type',state);
      return state;
  }
};

const initialState = {
  quantidade: 0,
};

const Activated = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};

export const Context = createContext(initialState);
export default Activated;
