import { useLocation } from "react-router-dom";
import { Flex, Image, useMediaQuery, Link } from "@chakra-ui/react";
import { useAuth } from "hooks/useAuth";
import logoMinhaConta from "assets/logo/dClube-logo.png";

interface SecondHeaderProps {
  isCategoryPage?: boolean;
}

export const SecondHeader = ({ isCategoryPage }: SecondHeaderProps) => {
  const [isMobileSize] = useMediaQuery("(max-width: 992px)");
  const location = useLocation();
  const parseLocation = location?.pathname?.split("/");
  const { dataUser } = useAuth();

  const pathName = parseLocation
    .map((item) => {
      const locationName = item?.split("-").join(" ");
      const parsedName =
        locationName.charAt(0).toUpperCase() + locationName.slice(1);
      return parsedName;
    })
    ?.filter((item) => item !== "" && item !== "Categoria");


    if (dataUser) 
      return null

  return (
    <>
      <Flex
        marginTop={isMobileSize ? "-20px" : "-20px"}
        width="100%"
        backgroundColor="#b40f15"
        height={isMobileSize ? "90px" : "140px"}
        alignItems="center"
        justifyContent="center"
        paddingY={isMobileSize ? "10px" : "20px"}
        top={0}
        zIndex={999}
      >
        <Link href="/">
          <Image
            src={logoMinhaConta}
            alt="Logo do DClube"
            height={ isMobileSize ? "50px" : "80px"}
            width={isMobileSize ? "50px" : "80px"}
            // zIndex={999999}
            position={'relative'}
          />
        </Link>
      </Flex>
    </>
  );
};
