import { HStack } from "@chakra-ui/react";
import { formattingNumberWithDecimals } from "utils";
import { RenderRowContentProducts } from "./RenderRowContentProducts";

type Item = {
  nomeProduto: string;
  precoUnitario?: number;
  precoTotal: number;
  descontoUnitario?: number;
  descontoTotal: number;
  quantidadeVendida?: number;
};

type TransacaoItemProps = {
  item: Item;
  cupom: string;
};

interface ReanderRowProductsProps {
  row?: any;
  transacaoItem?: TransacaoItemProps[];
}

export const RenderRowProducts = ({
  row,
  transacaoItem,
}: ReanderRowProductsProps) => {
  const transctionList = transacaoItem?.filter(
    ({ cupom }) => cupom === row.original.cupom
  );

  return (
    <>
      {!!transctionList?.length &&
        transctionList?.map(({ item }) => (
          <HStack spacing={5}>
            <RenderRowContentProducts
              produto={item.nomeProduto}
              quantidade={item.quantidadeVendida ?? 0}
              valor={formattingNumberWithDecimals(item.descontoTotal)}
            />
          </HStack>
        ))}
    </>
  );
};
