import React, { Fragment } from "react";
import {
  Table as TableChakra,
  TableContainer,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  chakra,
  Heading,
  Flex,
  useMediaQuery,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { useExpanded, useSortBy, useTable } from "react-table";
import { formattingNumberWithDecimals } from "utils";

type TransacaoItemProps = {
  nomeProduto: string;
  precoUnitario?: number;
  precoTotal: number;
  descontoUnitario?: number;
  descontoTotal: number;
  quantidadeVendida?: number;
};

type TransacoesProps = {
  cupom: string;
  loja: string;
  data: string;
  descontoTotal: number | string;
  transacaoItem?: TransacaoItemProps[];
};

type TableProps = {
  columns: any;
  data: TransacoesProps[];
  transacaoItem?: TransacaoItemProps[];
  descontoGeral?: number;
  renderRowSubComponent: any;
  isExpanded?: boolean;
};

export const Table = ({
  columns: userColumns,
  data,
  transacaoItem,
  descontoGeral,
  renderRowSubComponent,
}: TableProps) => {
  const [isMobileSize] = useMediaQuery("(max-width: 992px)");

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: expanded,
  } = useTable({ columns: userColumns, data }, useSortBy, useExpanded);

  const color = isMobileSize ? "transparent" : "#10125A";

  return (
    <>
      <TableContainer
        marginRight={!isMobileSize ? "-12px" : "none"}
        maxHeight={!isMobileSize ? "316px" : "210px"}
        overflowY="auto"
        overflowX={isMobileSize ? "auto" : "hidden"}
        
        sx={{
          "&::-webkit-scrollbar-thumb": {
            background: color,
            borderRadius: "24px",
          },
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "1px",
          },
        }}
      >
        <TableChakra
          size={isMobileSize ? "sm" : "md"}
          maxWidth="1132px"
          minWidth="335px"
          {...getTableProps()}
          variant="striped"
          colorScheme="gray"
          
        >
          <Thead backgroundColor="#EC1C24" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#EC1C24" }}>
            {!!headerGroups?.length &&
              headerGroups.map((headerGroup, index) => (
                <Fragment key={index}>
                  <Tr {...headerGroup.getHeaderGroupProps()} >
                    {!!headerGroup?.headers?.length &&
                      headerGroup.headers.map((column, index) => (
                        <Fragment key={index}>
                          <Th 
                            textTransform="capitalize"
                            
                            fontSize="15"
                            color="#FFFF"
                          >
                            {column?.render("Header")}
                            <chakra.span pl="4">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <TriangleDownIcon aria-label="decrescente" />
                                ) : (
                                  <TriangleUpIcon aria-label="ascendente" />
                                )
                              ) : null}
                            </chakra.span>
                          </Th>
                        </Fragment>
                      ))}
                  </Tr>
                </Fragment>
              ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {!!rows?.length &&
              rows.map((row: any, index) => {
                prepareRow(row);
                return (
                  <Fragment key={index}>
                    <Tr
                      fontSize="15"
                      fontWeight="normal"
                      color="#636262"
                      {...row.getRowProps()}
                      {...row.getToggleRowExpandedProps()}
                    >
                      {!!rows?.length &&
                        !!row?.cells?.length &&
                        row.cells.map((cell: any) => {
                          // Verifica se o valor da célula é da coluna que contém o ponto
                          if (cell.column.id === "ponto") {
                            const cellValue = cell.value;
                            return (
                              <Td
                                {...cell.getCellProps()}
                                style={{
                                  color: cellValue < 0 ? "#EC1C24" : "#4caf50",
                                  fontWeight: 'bold'
                                }}
                              >
                                {cell.render("Cell")}
                              </Td>
                            );
                          }

                          return (
                            <Td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </Td>
                          );
                        })}
                    </Tr>
                  </Fragment>
                );
              })}
          </Tbody>

        </TableChakra>
      </TableContainer>
      <Flex
        width="100%"
        marginLeft="auto"
        justifyContent={isMobileSize ? "center" : "flex-end"}
        marginTop="10px"
      >
      </Flex>
      <br/><br/>
    </>
  );
};
