import { useContext, useEffect, useState } from "react";
import {
  Button,
  Flex,
  Heading,
  Image,
  useMediaQuery,
  Box,
  VStack,
  Text,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input
} from "@chakra-ui/react";

import api from "services/api";
import { characterDelimiter } from "utils/characterDelimiter";
import { useCustomToast } from "hooks/useToast";
import { Context } from 'contexts/store';
import { Context as ContextCart } from 'contexts/cart';
import { useAuth } from "hooks/useAuth";
import { formattingNumberWithDecimals } from "utils";
import { useNavigate } from 'react-router-dom';
import { Breadcrumb_v2 } from "components/Breadcrumb/index_v2";
import "./style.scss";
import noPoints from 'assets/imagem/no-points.png';

const ResgatePage = () => {
  const [products, setProducts] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [isMobileSize] = useMediaQuery("(max-width: 992px)");
  const { getToast } = useCustomToast();
  const size = useWindowSize();
  const [originalProducts, setOriginalProducts] = useState<any>([]);
  const [productsSelectedIndustria, setProductsSelectedIndustria] = useState<any>([]);
  const [state, dispatch] = useContext<any>(Context)
  const { dataUser } = useAuth();
  const [haveDiscount, setHaveDiscount] = useState([])
  const navigate = useNavigate();
  const [newValueQuantity, setNewValueQuantity] = useState<any>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndexTwo, setCurrentIndexTwo] = useState(0);
  const [currentEan, setCurrentEan] = useState(0);
  const [showModalInput, setShowModalInput] = useState(false);
  const [totalPoints, setTotalPoints] = useState<number | string>('...');

  useEffect(() => {
    getProducts();
    verifyIsActivaded();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  
  const verifyIsActivaded = async () => {
    let { data } = await api.get("/motorDePontos/consultarCarrinhoAtivo");
    if(data.length > 0){
      setHaveDiscount(data)
    }else{
      setHaveDiscount([])
    }
  }

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: 0,
      height: 0,
    });
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
  }

  async function getProducts() {

    setLoading(true)
    const { data } = await api.get("/motorDePontos/produtos");

    const todosVazios = data.every((item: any) => item.produtos.length === 0);
    if(!todosVazios){
      setOriginalProducts(data)
      const newData = []
      
      for(var i = 0; i < data.length; i++){
        for(var j = 0; j < data[i].produtos.length; j++){
          const dataItem = data[i].produtos[j]
          dataItem.quantidade = 1
          dataItem.loading = false
          dataItem.pontosUnitario = dataItem.pontos
          newData.push(dataItem)
        }
      }

      let totalPoints = 0
      data.map((item: any) => {
        totalPoints = totalPoints + item.saldoPontos
      })
      setTotalPoints(totalPoints)
      setProductsSelectedIndustria(data)
      setProducts(data)
    }else {
      setProducts([])
    }
    setLoading(false)
  }

  const addQuantityItemCart = async (quantidade: number, i: number, ean: number, isModal = false, index2: number) => {
    let newData = [...products]
    newData[i].produtos[index2].quantidade =  isModal ? quantidade : quantidade + 1;
    setProducts(newData)
  }

  const addItemToCart = async ( i: number, ean: number, quantidade: number, index2: number) => {

    let newData = [...products]
    newData[i].produtos[index2].quantidade = quantidade
    newData[i].produtos[index2].loading = true
    setProducts(newData)
    
    let dataPost = {
      documento: dataUser.documento,
      ean: ean,
      quantidade: quantidade
    }
    let response
    try {
      response = await api.post('/motorDePontos/carrinho', dataPost);
      sucessoAddToCart(i, ean, newData, index2)
    } catch (e: any) {
      errorAddToCart( i, e.response.data.errors[0].message, index2)
    } 
  }

  const sucessoAddToCart = (i: number, ean: number, newData: any, index2: number) => {

    let data = [...newData]
    data[i].produtos[index2].quantidade = 1
    data[i].produtos[index2].pontos = originalProducts[i].produtos[index2].pontosUnitario
    data[i].produtos[index2].loading = false

    getToast({
      messageError: `Produto adicionado ao carrinho!`,
      status: "success",
      duration: 1000,
      position: 'top-left'
    });

    setProducts(data)
    updateCart(false, ean, i, newData, index2)
  }

  const errorAddToCart = (i: number, messageError = '', index2: number) => {

    let newData = [...products]
    newData[i].produtos[index2].loading = false
    
    setProducts(newData)

    getToast({
      messageError: messageError,
      status: "error",
      position: 'top-left'
    });
  
  }

  const updateCart = (selected: boolean, ean: number, index: number, newData: any, index2: number) => {
    if(!selected){
      let verifyIfHave = false
      let indexHave = 0
      
      if(state.products){
        verifyIfHave = state.products.some((item: any) =>   item.ean === ean );
        indexHave = state.products.findIndex((item: any) =>   item.ean === ean );
      }

      const productsState = [...state.products]

      if(verifyIfHave){
        productsState[indexHave] = newData[index].produtos[index2]
      }

      dispatch({ 
        type: "UPDATE_QUANT_CARRINHO", 
        quantidade: (state.quantidade == 0) ? 1 : verifyIfHave ? state.quantidade : state.quantidade + 1, 
        products: 
          state.products ?
              verifyIfHave ?
                productsState
              :
              [...state.products, newData[index].produtos[index2]]
            : 
              [products[index].produtos[index2]] 
      }) 
    }
    if(state.qunatidade == 0){
      return
    }
  }

  const openEditValueInput = (quantidade: number, ean: number, i: number, index2: number) => {
    setShowModalInput(true)
    setCurrentIndex(i)
    setCurrentIndexTwo(index2)
    setCurrentEan(ean)
    setNewValueQuantity(quantidade)
  }

  return (
    <Box marginTop={isMobileSize ? '-15px' : 0}>
      <br/>
      {!isMobileSize &&
        <Flex
            width="100%"
            maxWidth="1420px"
            margin="0px auto"
            justifyContent="space-between"
            alignItems="baseline"
            flexDirection={ isMobileSize ? 'column' : 'row' }
            marginTop={2}
          >
            <div style={{paddingLeft: isMobileSize ? 10 : 0}}>
              <Breadcrumb_v2
                uri={'carrinho'}
                categoriaSelecionadaNome={'Usar pontos'}
                categoriaSelecionadaPai={''}
              />
            </div>
        </Flex>
      }

      <Flex
        width="100%"
        maxWidth="1200px"
        paddingBottom={100}
        margin={isMobileSize ? "10px auto 10px auto" : "10px auto 10px auto"}
        justifyContent={'center'}
      >
          <Flex
            wrap="wrap"
            gap="20px"
            width="100%"
            marginLeft={isMobileSize ? 2 : 100}
            justifyContent={isMobileSize ? 'start' : 'start'}
          >
            {loading ? (
              <Flex margin="100px auto" justifyContent="center">
                <Spinner color="#EC1C24" size={"xl"} />
              </Flex>
            ) : (
              <>  

                {products.length == 0 && !loading &&
                  <Flex width="100%" maxWidth="826px" margin="0px auto" justifyContent={"center"} alignItems="center" flexDirection={"column"} style={{marginTop: 30}}>
                    <br/><br/>
                    <span style={{fontSize: isMobileSize ? 26 : 30, color: '#10125A', fontWeight: '500', paddingLeft: isMobileSize ? 36 : 40, paddingRight: isMobileSize ? 36 : 40, textAlign: 'center'}}>
                      Opa! Você ainda não tem pontos suficientes para ativar um produto :(
                    </span>
                    <br/>
                    <img src={noPoints} className="no-points"/>

                  </Flex>
                }
               
                {products.map((item: any, index: number) => {
                  return (
                    <>

                      {item.produtos.length > 0 &&
                        <div style={{width: '100%', background: '#eeeeee', borderRadius: 10, padding: 10, display: 'flex', paddingLeft: isMobileSize ? 5 : 20}}>
                          <div style={{flex: 1}}>
                            <span style={{fontWeight: 'bold', color: '#10125A'}}>
                              {item.industria}
                            </span>
                          </div>
                          <div style={{flex: 1, display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                            <span style={{fontWeight: 'bold', color: '#10125A'}}>
                              {item.saldoPontos} pontos
                            </span>
                          </div>
                        </div>
                      }

                      {item.produtos.map((item2: any, index2: number) => {
                        return (
                        <VStack key={index} style={{marginLeft: isMobileSize ? 0: 20, marginRight: isMobileSize ? 0: 20, paddingBottom: 60}}>
                          <Flex
                            justifyContent="center"
                            alignItems="center"
                            height={isMobileSize ? "290px" : "240px"}
                            width={isMobileSize ? size.width /2.2 : "220px"}
                            borderRadius="10px"
                            marginBottom={-5}
                            overflow="hidden"
                            cursor={'pointer'}
                          >
                            <Image
                              fallbackSrc="https://via.placeholder.com/230?text=Carregando..."
                              src={item2.imagem}
                              objectFit="contain"
                              marginBottom={isMobileSize ? 10 : 4}
                            />
                          </Flex>
                        
                          <Box
                            width={isMobileSize ? size.width/2.2 : "220px"}
                            height="60px"
                            borderRadius="12px"
                            zIndex={1}
                          >
                            <Text
                              fontSize="16px"
                              fontWeight="bold"
                              color="#10125A"
                              textAlign="center"
                              marginTop={isMobileSize ? -10 : 0}
                            >
                              {characterDelimiter(item2.nome, 40)}
                            </Text>
                          </Box>
                        <div className="container-discount-points">
                          <div className="item-discount">
                            <div className="title">
                              <span>
                                Descontos
                              </span>
                            </div>
                            <div className="sub-title">
                              <span>
                                {formattingNumberWithDecimals(item2.valorDesconto).replace('.', ',')}/un.
                              </span>
                            </div>
                          </div>
                          <div style={{height: '100%', flexDirection: 'column', width: 1, display: 'flex', background: '#EC1C24', justifyContent: 'center', alignItems: 'center'}}>
                          <div style={{height: '10%', width: 1, backgroundColor: '#EC1C24'}} />
                            <div style={{height: '80%', width: 1, backgroundColor: '#bdbdbd'}} />
                            <div style={{height: '10%', width: 1, backgroundColor: '#fff', border: 'none'}} />
                          </div>
                          <div className="item-points">
                            <div className="title">
                              <span>
                                Pontos
                              </span>
                            </div>
                            <div className="sub-title">
                              <span>
                                {item2.pontos}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="container-quant-addcart" style={{marginTop: 20}}>
                          <div className="item-quant">
                              {item2.loading ? 
                                  <div style={{ height: 26.5, width: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                      <Spinner color="#fff" size={'sm'} />
                                  </div>
                              :
                                <>
                                    <select name="quantidade" style={{width: isMobileSize ? 40 : 50, border: 'none'}} onChange={(e) => { 
                                        if(e.target.value == '9999'){
                                          return openEditValueInput(item2.quantidade, item2.ean, index, index2)
                                        }
                                        addQuantityItemCart(Number(e.target.value), index, item2.ean, true, index2)
                                      }} value={item2.quantidade} id="lang" className="select-quantity">
                                      {Array.from({length: 10}, (item2, index) => 
                                        <option value={index+1}>{index < 9 ? '0' : ''}{index + 1}</option>
                                      )}
                                      {item2.quantidade > 10 && 
                                        <option value={item2.quantidade}>{item2.quantidade}</option>
                                      }
                                      <option value={'9999'}>Mais de 10</option>
                                    </select>
                                </>
                              }
                          </div>
                          <button className="item-cart" onClick={() => {
                            addItemToCart(index, item2.ean, item2.quantidade, index2)
                          }}>
                            <span>
                              Adicionar ao carrinho
                            </span>
                          </button>
                        </div>
                      </VStack>
                      )})}
                    </>
                  )
                })}
                </>
              )
            }
          </Flex>
        </Flex>
      <Modal
        isOpen={showModalInput}
        onClose={() => setShowModalInput(false)}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent maxWidth={isMobileSize ? "90%" : '40%'}>
          <ModalHeader padding={7}>
            Digite a quantidade desejada
          </ModalHeader>
            <ModalCloseButton _focus={{border: 'none'}} />
          <ModalBody pb={6}>
            <span style={{ fontSize: 15, marginLeft: 5, fontWeight: 500, color: 'gray' }} >
              Quantidade de produtos
            </span>
            <Flex padding={1} borderRadius={8} width={300} flexDirection="column">
              <Input
                type='number'
                autoFocus
                style={{
                  width: 250,
                  paddingLeft: 10,
                  padding: 10,
                  border: '1px solid black',
                  borderRadius: 5,
                  fontSize: 16
                }}
                placeholder='Digite a quantidade'
                onChange={(e) => setNewValueQuantity(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    if(!newValueQuantity) return
                    if(newValueQuantity == '0'){
                      return
                    }
                    addItemToCart(currentIndex, currentEan, Number(newValueQuantity), currentIndexTwo)
                    setShowModalInput(false)
                  }
                }}
                value={newValueQuantity}
              />
            </Flex>
          </ModalBody>
          <Flex alignItems={'flex-end'} justifyContent={'flex-end'} padding={5}>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => {
                if(!newValueQuantity) return
                if(newValueQuantity == '0'){
                  return
                }
                addItemToCart(currentIndex, currentEan, Number(newValueQuantity), currentIndexTwo)
                setShowModalInput(false)
              }}
              disabled={loading}
              minWidth={100}
            >
            {loading ?
              <Spinner color="white" />
              :
              'Confirmar'
            }
            </Button>
          </Flex>
        </ModalContent>
      </Modal>
      <br/>
      <br/>
      <br/>
    </Box>
  );
};

export default ResgatePage;
