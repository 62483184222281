export const formatDateTime = (date: Date | string) => {
  const formatter = new Intl.DateTimeFormat("pt-BR", {
    month: "2-digit",
    year: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return formatter.format(new Date(date));
};

export const formatDate = (date: Date | string) => {
  const formatter = new Intl.DateTimeFormat("pt-BR", {
    month: "2-digit",
    year: "numeric",
    day: "2-digit",
  });

  return formatter.format(new Date(date));
};
