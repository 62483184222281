import { forwardRef, ForwardRefRenderFunction } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  useMediaQuery,
  InputProps,
  Input as ChakraInput,
} from "@chakra-ui/react";
import { FieldError } from "react-hook-form";

interface InputInterface extends InputProps {
  nameInput: string;
  label?: string;
  error?: FieldError;
  disabled? : boolean;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputInterface> = (
  { nameInput, label, error = null, disabled = false, ...rest },
  ref
) => {
  const [isMobileSize] = useMediaQuery("(max-width: 700px)");

  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel
          fontSize={isMobileSize ? "12px" : "15px"}
          fontWeight="bold"
          lineHeight={isMobileSize ? "0.5px" : "undefined"}
          htmlFor={nameInput}
        >
          {label}
        </FormLabel>
      )}

      <ChakraInput
        disabled={disabled}
        paddingLeft="10px"
        maxWidth={"100%"}
        minWidth="335px"
        height="40px"
        border="1px solid"
        borderColor="#DEDEDE"
        borderRadius={0}
        background="transparent"
        _focus={{ borderColor: "#ec1c24" }}
        id={nameInput}
        name={nameInput}
        ref={ref as any}
        {...rest}
      />

      {!!error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const Input = forwardRef(InputBase);
