import {
  forwardRef,
  ForwardRefRenderFunction,
  useState,
  useEffect,
  ChangeEventHandler,
} from "react";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  useMediaQuery,
} from "@chakra-ui/react";
import InputMask, { Props } from "react-input-mask";
import { FieldError } from "react-hook-form";

interface InputProps extends Props {
  nameInput: string;
  label?: string;
  error?: FieldError;
  isDate?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { nameInput, label, isDate, onChange, error = null, ...rest },
  ref
) => {
  const [isMobileSize] = useMediaQuery("(max-width: 700px)");
  const [backgroundColorState, setBackgroundColorState] = useState("#DEDEDE");

  const styles = {
    fontSize: isMobileSize ? "12px" : "15px",
    // fontWeight: "bold",
    border: "1px solid",
    borderRadius: 0,
    borderColor: backgroundColorState,
    background: "transparent",
    minWidth: "100%",
    height: 40,
    maxWidth: "100%",
    paddingLeft: 10,
  };

  useEffect(() => {
    if (error) {
      setBackgroundColorState("#ec1c24");
    } else {
      setBackgroundColorState("#DEDEDE");
    }
  }, [error]);

  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel
          fontSize={isMobileSize ? "12px" : "15px"}
          fontWeight="bold"
          lineHeight={isMobileSize ? "0.5px" : "undefined"}
          htmlFor={nameInput}
        >
          {label}
        </FormLabel>
      )}

      <InputMask
        onChange={onChange}
        alwaysShowMask={false}
        onFocus={() =>
          error
            ? setBackgroundColorState("#ec1c24")
            : setBackgroundColorState("#DEDEDE")
        }
        id={nameInput}
        name={nameInput}
        style={styles}
        ref={ref as any}
        {...rest}
      />

      {!!error && (
        <FormErrorMessage>
          {isDate ? "Formato de data incorreto" : error?.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export const InputReactMask = forwardRef(InputBase);
