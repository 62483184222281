import React, { useEffect, useState } from 'react';

const TimerCount = ({date, onTimerFinish, isHeader = false, isMenuMobile = false, size = 30, color = '#fff'}) => {

  date = new Date(date);
  date.setHours(date.getHours() -  3);
  
  const [timerExpireDiscount, setTimerExpireDiscount] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const novoTempoRestante = calcularTempoRestante(date);
      setTimerExpireDiscount(novoTempoRestante);

      if (novoTempoRestante.horas === 0 && novoTempoRestante.minutos === 0 && novoTempoRestante.segundos === 0) {
        clearInterval(intervalId);
        if (onTimerFinish) {
          onTimerFinish();
        }
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [date, onTimerFinish]);

  function calcularTempoRestante(dataAlvo) {
    
    const dataAlvoObj = new Date(dataAlvo);
    const dataAtual = new Date()
    const diferencaEmMilissegundos = dataAlvoObj - dataAtual;

    const horas = Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60));
    const minutos = Math.floor((diferencaEmMilissegundos % (1000 * 60 * 60)) / (1000 * 60));
    const segundos = Math.floor((diferencaEmMilissegundos % (1000 * 60)) / 1000);

    return { horas, minutos, segundos };
  }

  if(!timerExpireDiscount){
    return (
      <span style={{color: isHeader ? '#fff' : '#EC1C24', fontSize: size ? size : isHeader ? 14 : 30, fontWeight: '500'}}>...</span>
    )
  }

  return (
    <>
      {isHeader ? 
          <span style={{ fontSize: 14, fontWeight: '600', marginBottom: 0, color: '#ffff'}}>
            {timerExpireDiscount.horas}h {timerExpireDiscount.minutos}m {timerExpireDiscount.segundos}s
          </span>
        :
        isMenuMobile ?
          <span style={{ fontSize: 17, fontWeight: '600', marginBottom: 0, color: '#EC1C24'}}>
            <span style={{color: '#10125A', paddingRight: 10}}>
              Faça a compra em até
            </span>
            <br/>
            {timerExpireDiscount.horas}h {timerExpireDiscount.minutos}m {timerExpireDiscount.segundos}s
          </span>
        :
          <span style={{color: color ? color : '#EC1C24', fontSize: size ? size : 30, fontWeight: '500'}}>{timerExpireDiscount.horas}h {timerExpireDiscount.minutos}m {timerExpireDiscount.segundos}s</span>
      }
    </>
  );
};

export default TimerCount;
