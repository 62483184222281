import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
  useRef,
} from "react";

import { useDisclosure, UseDisclosureProps } from "@chakra-ui/react";

import api from "../services/api";

interface User {
  nomeCompleto: string;
  primeiroAcesso: boolean;
  cadastroId: number;
  documento: string;
  habilitaCartaoDClube: boolean;
}

interface DataUser {
  possuiAceiteRegulamento?: boolean;
  aceitaContatoTelefonico?: boolean;
  aceitaEmail?: boolean;
  aceitaSMS?: boolean;
  aceitaWhatsApp?: boolean;
  parceiroEmail?: boolean;
  parceiroSms?: boolean;
  parceiroWhatsApp?: boolean;
  confirmarSenha?: string;
  senha?: string;
  ativo?: boolean;
  celular?: string;
  dataNascimento?: Date | undefined;
  documento?: string;
  editouAniversario?: boolean;
  email?: string;
  genero?: string;
  id?: number;
  nome?: string;
  sobrenome?: string;
  imagem?: string;
  cep?: string;
  codigoFacebook?: string;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  login: string;
  senha: string;
}

interface SignInCredentialsFacebook {
  codigoFacebook: string;
  emailFacebook: string;
  origem: string;
}

interface AuthContextProps {
  user: User;
  dataUser: DataUser;
  isLogged: boolean;
  signIn(credentials: SignInCredentials): Promise<any>;
  signOut(): void;
  updateUser(user: DataUser): Promise<DataUser>;
  setDataUser: Dispatch<SetStateAction<DataUser>>;
  useDisclosure(): UseDisclosureProps;
  signInFacebook(credentials: SignInCredentialsFacebook): Promise<any>;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

type AuthProdiverProps = {
  children: ReactNode;
};

const AuthProvider = ({ children }: AuthProdiverProps) => {
  const userRef = useRef({} as User);
  const tokenRef = useRef("");
  const [isLogged, setIsLogged] = useState((): boolean => {
    let token = localStorage.getItem('@DClube:token')
    if (token) return true;
    return false;
  });
  
  const token = localStorage.getItem("@DClube:Admintoken") ?? localStorage.getItem("@DClube:token") ?? "";
  const dataStorage = localStorage.getItem("@DClube:user");

  const [dataUser, setDataUser] = useState<DataUser>((): any => {
    const dataUserLocal = localStorage.getItem("@DClube:dataUser");
    const routeName = window.location?.href;

    if (routeName.split('/home?')[1]) {
      const routeName = window.location?.href;
      let url: any = routeName.split('/home?')[1]
      url = decodeURIComponent('/home?' + url)
      const urlParams = new URLSearchParams(url);
      const tokenAdmin = urlParams.get('token');
      if (tokenAdmin) {
        api.defaults.headers.common["Authorization"] = `Bearer ${tokenAdmin}`;
        let nomeCompleto = url.split('nomeCompleto=')[1];
        nomeCompleto = nomeCompleto.split('&')[0];
        nomeCompleto = nomeCompleto.split('+').join(' ');
        const data = {
          "nomeCompleto": nomeCompleto,
          "primeiroAcesso": JSON.parse(urlParams.get('primeiroAcesso') ?? ""),
          "cadastroId": Number(urlParams.get('cadastroId')),
          "habilitaCartaoDClube": JSON.parse(urlParams.get('habilitaCartaoDClube') ?? ""),
          "possuiCartaoDClube": JSON.parse(urlParams.get('possuiCartaoDClube') ?? ""),
          "promotora": JSON.parse(urlParams.get('promotora') ?? "")
        }

        localStorage.setItem("@DClube:Admintoken", tokenAdmin);
        localStorage.setItem("@DClube:dataUser", JSON.stringify(data));
        localStorage.setItem("@DClube:user", JSON.stringify(data));
        return
      }
    } else if (token && dataStorage) {

      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      return ""
    }
  });

  const [data, setData] = useState<AuthState>(() => {
    if (token && dataStorage) {
      //api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      return { token, user: JSON.parse(dataStorage) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ login, senha }) => {
    const response = await api.post("/autenticacao/autenticar", {
      login,
      senha,
    });

    const { nomeCompleto, token, primeiroAcesso, cadastroId, documento, validado, celular, possuiCelular, habilitaCartaoDClube, possuiCartaoDClube, promotora } = response.data;
    const user = { nomeCompleto, primeiroAcesso, cadastroId, documento, habilitaCartaoDClube, possuiCartaoDClube, promotora };
    userRef.current = user;
    tokenRef.current = token;
    if (!validado) {
      const validateDataUser = {
        cadastroId: cadastroId,
        celular: celular,
        possuiCelular: possuiCelular
      };
      return validateDataUser
    } else {
      setData({ token: tokenRef.current, user: userRef.current });
      localStorage.setItem("@DClube:token", token);
      localStorage.setItem("@DClube:user", JSON.stringify(user));
      setIsLogged(true)
    }

    return true
  }, []);

  const signInFacebook = useCallback(async ({ codigoFacebook, emailFacebook, origem }) => {
    const response = await api.post("/autenticacao/autenticar", {
      codigoFacebook,
      emailFacebook,
      origem
    });

    const { nomeCompleto, token, primeiroAcesso, cadastroId, documento, validado, celular, possuiCelular, habilitaCartaoDClube, possuiCartaoDClube, promotora } = response.data;
    const user = { nomeCompleto, primeiroAcesso, cadastroId, documento, habilitaCartaoDClube, possuiCartaoDClube, promotora };
    userRef.current = user;
    tokenRef.current = token;
    if (!validado) {
      const validateDataUser = {
        cadastroId: cadastroId,
        celular: celular,
        possuiCelular: possuiCelular
      };
      return validateDataUser
    } else {
      setData({ token: tokenRef.current, user: userRef.current });
      localStorage.setItem("@DClube:token", token);
      localStorage.setItem("@DClube:user", JSON.stringify(user));
      setIsLogged(true)
    }

    return true
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("@DClube:token");
    localStorage.removeItem("@DClube:user");
    localStorage.removeItem("@DClube:dataUser");
    localStorage.clear();
    setData({} as AuthState);
    setDataUser({} as DataUser);
    setIsLogged(false)
  }, []);

  const updateUser = useCallback(
    async (dataUser: DataUser): Promise<any> => {
      try {
        await api.put("/cadastro", dataUser);
        localStorage.setItem("@DClube:dataUser", JSON.stringify(dataUser));
        setDataUser(dataUser);
      } catch (error) {
        console.log(error);
      }
    },
    [setDataUser]
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        dataUser,
        isLogged,
        signIn,
        signOut,
        updateUser,
        setDataUser,
        useDisclosure,
        signInFacebook,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextProps {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };