import { useState, useEffect } from "react";
import { Divider, Flex, Heading, Image, Link, Text } from "@chakra-ui/react";

import { ScrollToTop } from "components/ScrollToTop/ScrollToTop";
import { useAuth } from "hooks/useAuth";

import logoDClube from "assets/logo/dclube-logo-footer.png";
import logoZicard from "assets/logo/logo-zicard.svg";
import logoZicardNovo from "assets/logo/logo_atmosphera.png";
import iconFB from "assets/social/simple-facebook.png";
import iconIns from "assets/social/simple-instagram.png";
import iconYT from "assets/social/simple-youtube.png";

export const MobileFooterVersion = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [colorState, setColorState] = useState("#FFFF");
  const { user } = useAuth();

  function handleScroll() {
    if (window.scrollY > 400) {
      setShowTopBtn(true);
    } else {
      setShowTopBtn(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Flex position="relative" width="100%" flexDir="column" padding="0px 20px">
      <Image
        marginY="40px"
        w="150px"
        h="150px"
        src={logoDClube}
        alt="Logo do DeClube"
      />
      {!user &&
        <Heading fontWeight="bold" fontSize={20} color="#EC1C24">
          Fazer Login
        </Heading>
      }
      <Divider
        marginY={15}
        width="100%"
        orientation="horizontal"
        color="#FFFFFF"
      />
      <Link href="/nossa-historia">
        <Text
          fontWeight="regular"
          fontSize="14px"
          color="#FFFF"
        >
          Nossa história
        </Text>
      </Link>

      <Heading marginTop={5} fontWeight="bold" fontSize={20} color="#EC1C24">
        <a href="/faq">
          Perguntas Frequentes
        </a>
      </Heading>
      <Divider
        marginY={15}
        width="100%"
        orientation="horizontal"
        color="#FFFFFF"
      />
      <Link href="/no-que-acreditamos">
        <Text
          
          fontWeight="regular"
          fontSize="14px"
          color="#FFFF"
        >
          No que acreditamos
        </Text>
      </Link>

      <Heading marginTop={5} fontWeight="bold" fontSize={20} color="#EC1C24">
        <a href="/contato">
          Contato
        </a>
      </Heading>
      <Divider
        marginY={15}
        width="100%"
        orientation="horizontal"
        color="#FFFFFF"
      />
     
      <Link marginBottom={10} href="trabalhe-conosco">
        <Text
          
          fontWeight="regular"
          fontSize="14px"
          color="#FFFF"
        >
          Trabalhe conosco
        </Text>
      </Link>

      <ScrollToTop color={colorState} showTopBtn={showTopBtn} />

      <Divider
        marginY={15}
        width="100%"
        orientation="horizontal"
        color="#FFFFFF"
      />
      <Flex
        marginTop={5}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Image src={iconFB} alt="" />
        <Image margin="0px 50px 0px 50px" src={iconIns} alt="" />
        <Image src={iconYT} alt="" />
      </Flex>
      <Flex
        direction="row"
        width="100%"
        alignItems="center"
        justifyContent="center"
        margin="0px auto 50px auto"
      >
        <Image width="55%" marginTop={20} src={logoZicardNovo} alt="logo-zicard" />
      </Flex>
      <Flex
        direction="column"
        width="100%"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Text
          lineHeight="0.5px"
          fontSize="10px"
          color="#FFFF"
        >
          Utilizamos cookies para melhorar a experiência de navegação.
        </Text>

        <Flex
          width="100%"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Link href="/politica-de-privacidade">
            <Text
              marginRight={2}
              textDecor="underline"
              fontWeight="bold"

              fontSize="10px"
              color="#FFFF"
            >
              Politicas de Privacidade.{" "}
            </Text>
          </Link>
          <Link href="/termos-e-condicoes">
            <Text
              fontWeight="bold"
              fontSize="10px"
              marginRight={2}
              textDecor="underline"
              color="#FFFF"
            >
              Termos de Uso.
            </Text>
          </Link>
          <Link href="/regulamento">
            <Text
              fontWeight="bold"
              fontSize="10px"
              
              textDecor="underline"
              color="#FFFF"
            >
              Regulamento.
            </Text>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};
