import { useState } from "react";
import { Flex, useMediaQuery } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import MENU_TRANSACOES_MOCK from "mocks/menuTransacoes";

import { SecondHeader } from "components/SecondHeader";
import { NavMenu } from "components/NavMenu";
import { HeadingTable } from "./components/HeadingTable";
import { TableTransaction } from "./components/TableTransaction";

export default function ExtratoAtivacaoPage() {
  const [isMobileSize] = useMediaQuery("(max-width: 992px)");
  const [isShowTable, setIsShowTable] = useState(true);
  const location = useLocation();
  const routeName = location?.pathname.includes("/extrato-de-pontos");

  return (
    <>
      <Flex width="100%" maxWidth="1420px" height="auto" margin="0px auto">
        {!isMobileSize && (
          <Flex width="100%" maxWidth="288px">
            <NavMenu
              fontColorActive={routeName ? "#10125A" : ""}
              menuList={MENU_TRANSACOES_MOCK}
              isTransaction
              routeName="Extrato de Pontos"
            />
          </Flex>
        )}

        <Flex width="100%" marginTop={isMobileSize ? '20px' : "50px"}>
          <Flex
            flexDirection="column"
            width="100%"
            padding={isMobileSize ? "0px 15px" : "none"}
          >
            <HeadingTable
              isShowTable={isShowTable}
              setIsShowTable={setIsShowTable}
            />
            {isMobileSize ? (
              <>{isShowTable && <TableTransaction />}</>
            ) : (
              <TableTransaction />
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
